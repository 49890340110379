import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";
import './thank-you.scss';


export default ({location}) => {

    return (<ThankYouPage location={location}>
      <section id="main" className="main-title-section main-title-section-dark webinar">
        <div className="page-content container">
        <div className="row items-center justify-center">
        <div className="col-12 col-md-6 webinar-thank-you">
          <div className="page_icon flex justify-center mb-6"><img src="https://amazeeio.cdn.prismic.io/amazeeio/cdd4fb59-26a5-4429-bf15-90cd108b0951_Thankyou-landing.svg" alt="Thank you" /></div>
          <h1>Thank you!</h1>
          <p>Thank you for signing up to watch our Webinar, a link to the recording has been emailed to your inbox, or click below if you would prefer to watch it now.</p>
          <p><a href="https://amazee.io/watch-winning-with-webops"><div className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted inline-block mt-2 mb-4">Watch Webinar</div></a></p>
          <p><a href="/resources">View our additional Resources </a></p>
          </div>
          </div>
        </div>
      </section>
      </ThankYouPage>
    );
}
